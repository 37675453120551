import React from 'react'
import { css } from 'emotion'

const main = css`
  color: white;
  background: #181c27;
  display: flex;
  justify-content: center;
  padding: 20px;
  font-size: 13px;
  margin-top: auto;
`

const Footer = () => {
  return (
    <footer className={main}>
      <p>©{new Date().getFullYear()} Caroline Chetwood</p>
    </footer>
  )
}

export default Footer
