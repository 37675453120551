import React from 'react'
import { Link } from 'gatsby'
import { css, cx } from 'emotion'
import { breakpoint } from '../vars'
import menuImg from '../images/menu-bg.jpg'

const menuItems = [
  {
    text: 'Home',
    path: '/',
  },
  {
    text: 'Services',
    path: '/services',
  },
  {
    text: 'Experience',
    path: '/experience',
  },
  {
    text: 'Contact',
    path: '/contact',
  },
]

const menuStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;

  @media (min-width: ${breakpoint}px) {
    flex-direction: row;
    background: url(${menuImg});
  }
`

const linkStyle = css`
  line-height: 46px;
  display: flex;
  color: black;
  font-family: Georgia, 'Times New Roman', Serif;
  font-size: 18px;
  text-shadow: 0px 1px 0px #bac9ee;

  @media (max-width: ${breakpoint - 1}px) {
    background-image: url(${menuImg});
    justify-content: center;
    width: 100%;
  }

  @media (min-width: ${breakpoint}px) {
    margin-left: 40px;
    margin-right: 40px;
  }

  &.active {
    color: white;
    position: relative;
    text-shadow: none;

    @media (min-width: ${breakpoint}px) {
      &:before {
        content: '';
        width: 0;
        height: 0;
        border-left: 14px solid transparent;
        border-right: 14px solid transparent;
        border-top: 14px solid #1f2746;
        position: absolute;
        left: 50%;
        margin-left: -14px;
        top: 45px;
      }

      &:after {
        content: '';
        width: 0;
        height: 0;
        border-left: 14px solid transparent;
        border-right: 14px solid transparent;
        border-top: 14px solid #7481ae;
        position: absolute;
        left: 50%;
        margin-left: -14px;
        top: 44px;
      }
    }
  }
`

const linkStyleMobMenu = css`
  @media (min-width: ${breakpoint}px) {
    display: none;
  }
`

const linkStyleClosed = css`
  @media (max-width: ${breakpoint - 1}px) {
    display: none;
  }
`

class Menu extends React.Component {
  state = {
    mobileMenuOpen: false,
  }
  render() {
    return (
      <ul className={menuStyle}>
        <li>
          <button
            onClick={() =>
              this.setState({
                mobileMenuOpen: !this.state.mobileMenuOpen,
              })
            }
            className={cx(linkStyle, linkStyleMobMenu)}
          >
            Menu
          </button>
        </li>
        {menuItems.map(item => {
          return (
            <li key={item.text}>
              <Link
                onClick={() =>
                  this.setState({
                    mobileMenuOpen: false,
                  })
                }
                to={item.path}
                className={
                  this.state.mobileMenuOpen
                    ? linkStyle
                    : cx(linkStyle, linkStyleClosed)
                }
                activeClassName="active"
              >
                {item.text}
              </Link>
            </li>
          )
        })}
      </ul>
    )
  }
}

export default Menu
