import React from 'react'
import { css, cx } from 'emotion'

import imgPhone from '../../images/contact-icons/phone.png'
import imgPhoneHover from '../../images/contact-icons/phone-over.png'
import imgEmail from '../../images/contact-icons/mail.png'
import imgEmailHover from '../../images/contact-icons/mail-over.png'
import imgLinkedin from '../../images/contact-icons/linkedin.png'
import imgLinkedinHover from '../../images/contact-icons/linkedin-over.png'
import imgSkype from '../../images/contact-icons/skype.png'
import imgSkypeHover from '../../images/contact-icons/skype-over.png'

import { phone, phoneReadable, email, linkedIn, skype } from '../../data'

const icon = css`
  display: inline-flex;
  align-items: center;

  &:before {
    content: '';
    width: 42px;
    height: 42px;
    display: inline-flex;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
`

const phoneStyles = css`
  &:before {
    background-image: url(${imgPhone});
  }
  &:hover:before {
    background-image: url(${imgPhoneHover});
  }
`

const mailStyles = css`
  &:before {
    background-image: url(${imgEmail});
  }

  &:hover:before {
    background-image: url(${imgEmailHover});
  }
`

const linkedinStyles = css`
  &:before {
    background-image: url(${imgLinkedin});
  }
  &:hover:before {
    background-image: url(${imgLinkedinHover});
  }
`

const skypeStyles = css`
  &:before {
    background-image: url(${imgSkype});
  }
  &:hover:before {
    background-image: url(${imgSkypeHover});
  }
`

const textStyles = css`
  margin-left: 10px;
  font-size: 14px;
`

const ContactIcon = ({ type, text, style }) => {
  if (type === 'phone') {
    return (
      <a href={`tel:${phone}`} className={cx(icon, phoneStyles)} style={style}>
        <span className={text ? textStyles : 'visuallyhidden'}>
          {phoneReadable}
        </span>
      </a>
    )
  } else if (type === 'email') {
    return (
      <a
        href={`mailto:${email}`}
        className={cx(icon, mailStyles)}
        style={style}
      >
        <span className={text ? textStyles : 'visuallyhidden'}>Email me</span>
      </a>
    )
  } else if (type === 'linkedin') {
    return (
      <a
        href={linkedIn}
        target="_blank"
        rel="noopener noreferrer"
        className={cx(icon, linkedinStyles)}
        style={style}
      >
        <span className={text ? textStyles : 'visuallyhidden'}>LinkedIn</span>
      </a>
    )
  } else if (type === 'skype') {
    return (
      <a
        href={`skype:${skype}`}
        className={cx(icon, skypeStyles)}
        style={style}
      >
        <span className={text ? textStyles : 'visuallyhidden'}>{skype}</span>
      </a>
    )
  } else {
    return <h1>ERROR</h1>
  }
}

export default ContactIcon
