import React from 'react'
import { Link } from 'gatsby'
import { css } from 'emotion'
import logoImg from '../images/logo.png'
import { breakpoint } from '../vars'
import { phone, phoneReadable } from '../data'
import Menu from './menu'

import ContactIcon from './small/contact-icon'

const Logo = () => {
  const logo = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    line-height: 22px;

    @media (min-width: ${breakpoint}px) {
      flex-direction: row;

      img {
        margin-right: 10px;
      }
    }
  `
  return (
    <h1>
      <Link to="/" className={logo}>
        <img src={logoImg} alt="Caroling Chetwood" />
        <span>
          Chartered Accountant <br />& Chartered Tax Adviser
        </span>
      </Link>
    </h1>
  )
}

const Contact = () => {
  const cont = css`
    display: none;

    @media (min-width: ${breakpoint}px) {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  `
  const phoneStyles = css`
    font-size: 32px;
    margin-bottom: 10px;
    text-shadow: rgb(61, 71, 96) 0px 2px 0px;
  `
  return (
    <div className={cont}>
      <a href={`tel:${phone}`} className={phoneStyles}>
        {phoneReadable}
      </a>
      <div style={{ marginTop: '10px' }}>
        <ContactIcon type="email" />
        <ContactIcon type="skype" style={{ margin: '0 20px' }} />
        <ContactIcon type="linkedin" />
      </div>
    </div>
  )
}

const Header = () => {
  const header = css`
    background: #515e80;
    padding-top: 20px;
  `
  const headerInner = css`
    max-width: 960px;
    margin: auto;
    padding: 0 10px;

    a {
      color: white;
    }

    @media (min-width: ${breakpoint}px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  `

  return (
    <div className={header}>
      <div className={headerInner}>
        <Logo />
        <Contact />
      </div>
      <Menu />
    </div>
  )
}

export default Header
